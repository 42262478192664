import React from 'react'
import { Container } from 'react-bootstrap'

export default function Community() {
  return (
    <>
        <Container>
            <h1>Community</h1>
        </Container>
    </>
  )
}